'use strict';
import $ from 'jquery';
import 'moment';
import queryString from 'query-string';
// import Pikaday from 'pikaday';
export default class ResourceFilters {
	constructor() {
		let $listingFilter = $('.listing-filter__resource');

		// let pickerFrom = new Pikaday({
		// 	field: document.getElementById('datepicker-from'),
		// 	format: 'DD MMM YYYY',
		// 	onSelect: function () {
		// 		console.log(this.getMoment().format('Do MMMM YYYY'));
		// 	}
		// });

		// let pickerTo = new Pikaday({
		// 	field: document.getElementById('datepicker-to'),
		// 	format: 'DD MMM YYYY',
		// 	onSelect: function () {
		// 		console.log(this.getMoment().format('Do MMMM YYYY'));
		// 	}
		// });

		if(location.search){
			const parsed = queryString.parse(location.search, {
				decode: true
			});

			for (var key in parsed) {
				$(`#${key}`).val(parsed[key]);
			}

			$('.listing-filter__resource select').map((i, ele) => {
				let $this = $(ele);

				let value = $this.val(),
					text = $this.find(`option[value="${value}"]`).first().text();

				$this.prev().find('button').text(text);
			});
		}

		$('button.clear-form', $listingFilter).on('click', function (e) {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			$('input').each(function(){
				let $this = $(this);

				$this.val('');
			});

			$('select', $listingFilter).each(function () {
			  	let $this = $(this);
				let defaultText = $this.find('option').first().text();

				$this.val('');
				$this.prev().find('button').text(defaultText);
			});
		});

		let listingFilterURL = $listingFilter.data('searchlink');

		$('button[type="submit"]', $listingFilter).on('click', function (e) {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			let dateFromMonth = $('#dateFromMonth #monthfrom', $listingFilter).val(),
				dateFromYear = $('#dateFromYear #yearfrom', $listingFilter).val(),
				dateToMonth = $('#dateToMonth #monthto', $listingFilter).val(),
				dateToYear = $('#dateToYear #yearto', $listingFilter).val(),
				practiceSector = $('#practice-sector #practice', $listingFilter).val(),
				articleType = $('#article-type #type', $listingFilter).val();

			window.location.href = getQuery(listingFilterURL, dateFromMonth, dateFromYear, dateToMonth, dateToYear, practiceSector, articleType);
		});

		function getQuery(pageURL, dateFromMonth, dateFromYear, dateToMonth, dateToYear, practiceSector, articleType) {
			return pageURL + '?monthfrom=' + dateFromMonth + '&yearfrom=' + dateFromYear + '&monthto=' + dateToMonth + '&yearto=' + dateToYear + '&practice=' + practiceSector + '&type=' + articleType;
		}
	}
}
