'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class SiteHeader {
	constructor() {
		let _this = this,
			$siteHeader = $('.site-header'),
			$siteHeaderBg = $('.site-header__background', $siteHeader),
			$navController = $('.nav-controller', $siteHeader);

		// START: Press 'Esc' to close navigation menu
		$(document).keyup(function(e) {
			if (e.keyCode == 27) { // escape key maps to keycode `27`
				closeMenu($siteHeader, $navController);

				_this.setCustomColor();
			}
		});
		// END

		$('.site-header__menu').on('click', function(e){
			e.stopPropagation();
		});

		$('body').on('click', function(){
			closeMenu($siteHeader, $navController);
		});

		// START: Open navigation bar
		$navController.on('click', function(e){
			e.stopPropagation();
			let $this = $(this);

			if($siteHeader.hasClass('open')){
				$('body').removeClass('fixed');
				$('.site-header__menu').scrollTop(0);

				if($(window).width() > 1023){
					$siteHeader.removeClass('show-nav');

					setTimeout(function(){
						$siteHeader.removeClass('open');
						$this.removeClass('active');
					}, 300);
				}
				else {
					$siteHeader.removeClass('open');
					$this.removeClass('active');
				}

				_this.setCustomColor();

			} else {
				$('body').addClass('fixed');

				if($(window).width() > 1023){
					$siteHeader.addClass('open');
					$this.addClass('active');

					setTimeout(function(){
						$siteHeader.addClass('show-nav');
					}, 300);
				}
				else {
					$siteHeader.addClass('open');
					$this.addClass('active');
				}

				_this.setOriginalColor();
			}
		});

		enquire.register("screen and (min-width: 1024px)", {
			match: function() {
				if($siteHeader.hasClass('open')){
					$siteHeader.addClass('show-nav');
				}
			},
			unmatch: function(){
				if($siteHeader.hasClass('show-nav')){
					$siteHeader.removeClass('show-nav');
				}
			}
		});
		// END

		// START: Fade in site header on scroll
		enquire.register("screen and (min-width: 1024px)", {
			match: function(){
				if($(window).scrollTop() > 50){
					$siteHeaderBg.css('opacity', '1');

					$('.site-header .nonscroll-desktop').css('display', 'none');
					$('.site-header .scroll-desktop').css('display', 'block');

					_this.setOpacity($siteHeaderBg);
				}
				else {
					let opacity = $(window).scrollTop() / 50;
					$siteHeaderBg.css('opacity', opacity);

					$('.site-header .nonscroll-desktop').css('display', 'block');
					$('.site-header .scroll-desktop').css('display', 'none');

					_this.setCustomColor();

					_this.setOpacity($siteHeaderBg);
				}
			},
			unmatch: function(){
				$(window).off('scroll');
				$('.site-header .nonscroll-desktop').removeAttr('style');
				$('.site-header .scroll-desktop').removeAttr('style');
			}
		});
		// END

		function closeMenu($header, $navController) {
			if($(window).width() > 1023){
				if($header.hasClass('open')){
					$('body').removeClass('fixed');
					$('.site-header__menu').scrollTop(0);

					$header.removeClass('show-nav');

					setTimeout(function(){
						$header.removeClass('open');
						$navController.removeClass('active');
					}, 300);
				}
				else {
					return;
				}
			}
			else {
				if($header.hasClass('open')){
					$('body').removeClass('fixed');
					$('.site-header__menu').scrollTop(0);

					$header.removeClass('open');
					$navController.removeClass('active');
				}
				else {
					return;
				}
			}
		}
	}

	setOpacity($object){
		let _self = this;

		$(window).on('scroll', function(){
			if($(window).scrollTop() <= 50){
				let opacity = $(window).scrollTop() / 50;

				$object.css('opacity', opacity);

				$('.site-header .nonscroll-desktop').css('display', 'block');
				$('.site-header .scroll-desktop').css('display', 'none');

				_self.setCustomColor();
			}
			else {
				$object.css('opacity', '1');

				$('.site-header .nonscroll-desktop').css('display', 'none');
				$('.site-header .scroll-desktop').css('display', 'block');

				_self.setOriginalColor();
			}
		});
	}

	setCustomColor() {
		if ($('.site-header').hasClass('is-home')) {
			let $siteHeader = $('.site-header'),
				linkColor = $siteHeader.data('color'),
				linkHoverColor = $siteHeader.data('hover-color');

			let $headerDesktopLang = $('.site-header__links--language.desktop-only');

			$('a', $headerDesktopLang).each(function(e) {
				$(this).css('color', linkColor);

				if ($(this).parent().hasClass('active')) {
					$(this).css('color', linkHoverColor);
				}
			});

			$('a', $headerDesktopLang).on("mouseover", function() {
				$(this).css('color', linkHoverColor);
			}).on("mouseout", function() {
				$(this).css('color', linkColor);

				if ($(this).parent().hasClass('active')) {
					$(this).css('color', linkHoverColor);
				}
			});

			let $headerDesktopMail = $('.site-header__links--links');

			if (!$siteHeader.hasClass('show-nav')) {
				$('a', $headerDesktopMail).each(function(e) {
					$(this).css('color', linkColor);
				});

				$('a', $headerDesktopMail).on("mouseover", function() {
					$(this).css('color', linkHoverColor);
				}).on("mouseout", function() {
					$(this).css('color', linkColor);
				});
			}

			setTimeout(function(){
				if ($siteHeader.hasClass('show-nav')) {
					$('a', $headerDesktopMail).each(function(e) {
						$(this).removeAttr('style');
					});

					$('a', $headerDesktopMail).on("mouseover", function() {
						$(this).removeAttr('style');
					}).on("mouseout", function() {
						$(this).removeAttr('style');
					});
				}
			}, 300);

			if ($(window).width() > 1023) {
				let $headerHamburger = $('.site-header__hamburger');

				$('.line', $headerHamburger).each(function(e) {
					$(this).css('background-color', linkHoverColor);
				});
			}
		}
	}

	setOriginalColor() {
		if ($('.site-header').hasClass('is-home')) {
			let $siteHeader = $('.site-header'),
				linkColor = $siteHeader.data('color'),
				linkHoverColor = $siteHeader.data('hover-color');

			let $headerDesktopLang = $('.site-header__links--language.desktop-only');

			$('a', $headerDesktopLang).each(function(e) {
				$(this).removeAttr('style');
			});

			$('a', $headerDesktopLang).on("mouseover", function() {
				$(this).removeAttr('style');
			}).on("mouseout", function() {
				$(this).removeAttr('style');
			});

			let $headerDesktopMail = $('.site-header__links--links');

			$('a', $headerDesktopMail).each(function(e) {
				$(this).removeAttr('style');
			});

			$('a', $headerDesktopMail).on("mouseover", function() {
				$(this).removeAttr('style');
			}).on("mouseout", function() {
				$(this).removeAttr('style');
			});

			if ($(window).width() > 1023) {
				let $headerHamburger = $('.site-header__hamburger');

				$('.line', $headerHamburger).each(function(e) {
					$(this).removeAttr('style');
				});
			}
		}
	}
}
