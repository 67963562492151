'use strict';
import $ from 'jquery';
import typeahead from 'jquery-typeahead';
import queryString from 'query-string';


export default class LawyerFilters {
	constructor() {
		let autoCompleteData = JSON.parse($('#lawyerNames').html());

		let userInput = '';

		$.typeahead({
			input: '.js-typeahead',
			order: 'asc',
			minLength: 1,
			maxItem: 8,
			source: {
				data: autoCompleteData
			},
			filter: function (item, displayKey) {
				let userInput = $('.js-typeahead').val();

				let regex = `^${userInput}[a-zA-Z]*|[^a-zA-Z][ ]*${userInput}[a-zA-Z]`;
				let pattern = new RegExp(regex, "gi");

				if (pattern.test(displayKey)) {
					return true;
				}
				else {
					return false;
				}
			}
		});

		if(location.search){
			const parsed = queryString.parse(location.search, {decode: true});

			// Set select value to parameter in the query string
			for (var key in parsed) {
				$(`#${key}`).val(parsed[key]);
			}

			// Set parameter values into input text box and select clone button
			$('.listing-filter__lawyer select, .home-search select').map((i, ele) => {
				let $this = $(ele);

				let value = $this.val(),
					text = $this.find(`option[value="${value}"]`).text();

				$this.prev().find('button').text(text);
			});

			let navHeight = $('.site-header').outerHeight();

			if ($('.listing-results__lawyer').length) {
				$('html, body').stop(true, true).animate({
					scrollTop: $('.listing-results__lawyer').offset().top - navHeight - 30
				}, 500);
			}
		}

		/**
		 * START: Lawyer search query string generator
		 */
		let $lawyerExpertiseSearch = $('.lawyer-expertise-search'),
			lawyerSearchURL = $lawyerExpertiseSearch.data('searchlink'),
			$search = $('.search-button .search-button__lawyer', $lawyerExpertiseSearch);


		$search.on('click', function(e) {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			let lawyerName = $('#lawyer-name input', $lawyerExpertiseSearch).val(),
				practiceSector = $('#practice-sector #practice', $lawyerExpertiseSearch).val();

			window.location.href = getQuery(lawyerSearchURL, lawyerName, practiceSector);
		});

		$('input#name', $lawyerExpertiseSearch).keyup(function (e) {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			if (e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				$search.trigger('click');
			}
		});

		function getQuery(pageURL, lawyerName, practiceSector) {
			return pageURL + '?name=' + encodeURIComponent(lawyerName) + '&practice=' + practiceSector;
		}
		/**
		 * END: Lawyer search query string generator
		 */

		/**
		 * START: Expertise search handlers
		 */
		let $expertiseSearch = $('select#expertise', $lawyerExpertiseSearch),
			$expertiseSearchButton = $('.search-button .search-button__expertise', $lawyerExpertiseSearch);

		$expertiseSearch.on('change', function () {
			let value = $(this).val();

			if(value){
				$expertiseSearchButton.attr('href', value);
			}
		});
		/**
		 * END: Expertise search handlers
		 */
	}
}
