'use strict';

import $ from 'jquery';
export default class Profile {
	constructor() {
		let $profile = $('.profile');

		// On load
		let imageHeight = $('.profile__image img').height();
		$profile.css('min-height', imageHeight);

		// On resize
		$(window).on('resize', function(){
			let imageHeight = $('.profile__image img').height();

			$profile.css('min-height', imageHeight);
		});
	}
}
