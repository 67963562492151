'use strict';

import $ from 'jquery';
import queryString from 'query-string';

export default class SearchListing {
	constructor() {
		let $searchListing = $('.search-listing');

		if(location.search){
			const parsed = queryString.parse(location.search);

			$('input.form-control', $searchListing).val(parsed.searchtext);
		}

		$('input.form-control', $searchListing).attr('placeholder', 'Enter a keyword...');

		$('input.btn', $searchListing).after('<button class="cta search-submit-clone" type=""><span>Search</span></button>');

		$('button.search-submit-clone', $searchListing).on('click', function(e){
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			$('input.btn', $searchListing).trigger('click');
		});
	}
}
