'use strict';

import $ from 'jquery';
import Cookies from 'js-cookie';

export default class CoverPage {
	constructor() {
		let $coverPage = $('.cover-page'),
			$coverPageClose = $('.close-btn', $coverPage);

		if(!getCookie()){
			$('body').addClass('cover-page-active');
			$coverPage.addClass('active');
			$coverPage.fadeIn(1000);

			// Bind handlers if cover page is active
			$coverPageClose.on('click', function(e){
				e.stopPropagation();
				e.preventDefault ? e.preventDefault() : (e.returnValue = false);


				if($coverPage.hasClass('active')){
					$coverPage.fadeOut(500, function(){
						$coverPage.removeClass('active');
						$('body').removeClass('cover-page-active');
						setCookie();
					});
				}
				else {
					return;
				}
			});

			// Trigger click on close button when clicking on empty space in cover page
			$coverPage.on('click', function(e){
				e.stopPropagation();

				$coverPageClose.trigger('click');
			});

			// Prevent action in video or image from propagating to trigger click on close button
			$('img, video').on('click', function(e){
				e.stopPropagation();
			});
		}
		else {
		}

		function setCookie(){
			Cookies.set('closeCoverPage', true, { expires: 3 });
		}

		function getCookie(){
			return Cookies.get('closeCoverPage');
		}
	}
}
