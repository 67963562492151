'use strict';

import $ from 'jquery';

export default class SelectClone {
	constructor() {
		$('select').map((i, ele) => {
			let $this = $(ele);

			$this.wrap('<div class="select-clone-pair"></div>');

			let option, optionValue;

			if ($('option', $this).filter(':selected').val()) {
				option = $this.find(':selected').text(),
				optionValue = $this.find(':selected').val();
			} else {
				option = $('option', $this).first().text(),
				optionValue = $('option', $this).first().val();
			}

			let _clone = `<div class="select-clone"><button type="button" value="${optionValue}">${option}</button><i class="icon-chevron-down"></i></div>`;

			// Append clone button
			$this.before(_clone);

			let $copy = $('.select-clone', $this.parent()),
				$button = $copy.find('button');

			$button.on('click', function(e){
				e.preventDefault ? e.preventDefault() : (e.returnValue = false);
			});

			$this.on('click', (e) => {
				e.stopPropagation();

				if (!$copy.hasClass('active')){
	        		$copy.addClass('active');
				}
				else {
	        		$copy.removeClass('active');
				}

				$('.select-clone').not($copy).removeClass('active');
			});

			$this.on('change', () => {
				let $option = $this.find(':selected');

				$this.prev().find('button').text($option.text());
			});

			$(window).on('click', () => {
				if ($copy.hasClass('active')){
					$copy.removeClass('active');
				}
			});
		});
	}
}
