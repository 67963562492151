'use strict';

import $ from 'jquery';
export default class Form {
	constructor() {
		let isLawyerContactFieldName = $('.islawyercontact .radio input').attr('name');

		$('input[name="' + isLawyerContactFieldName + '"]').on('change', function () {
			let value = $(this).val();

			if(value == 'Yes'){
				$('.lawyername input').prop('disabled', false);
			}
			else {
				$('.lawyername input').prop('disabled', true);
			}
		});

		$('input.FormButton.btn').map((i, ele) => {
			let $this = $(ele),
				$scope = $this.parent(),
				buttonLabel = $this.val();

			$this.after(`<button class="cta search-submit-clone" type=""><span>${buttonLabel}</span></button>`);
			$this.addClass('visuallyHidden');

			$('button.search-submit-clone', $scope).on('click', function (e) {
				e.preventDefault ? e.preventDefault() : (e.returnValue = false);

				$this.trigger('click');
			});
		});
	}
}
