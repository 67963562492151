// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';

import TableResponsive from '../_modules/common/table-responsive/table-responsive';
import Carousel from '../_modules/common/carousel/carousel';
import SelectClone from '../_modules/common/select-clone/select-clone';

import BackToTop from '../_modules/atoms/back-to-top/back-to-top';

import CoverPage from '../_modules/molecules/cover-page/cover-page';
import TextSnippet from '../_modules/molecules/text-snippet/text-snippet';
import HeaderSearch from '../_modules/molecules/header-search/header-search';
import PageActions from '../_modules/molecules/page-actions/page-actions';

import SiteHeader from '../_modules/organisms/site-header/site-header';
import Tab from '../_modules/organisms/tab/tab';
import ResourceFilters from '../_modules/organisms/resource-filters/resource-filters';
import LawyerFilters from '../_modules/organisms/lawyer-filters/lawyer-filters';
import ExpertiseContacts from '../_modules/organisms/expertise-contacts/expertise-contacts';
import Form from '../_modules/organisms/form/form';
// import HomeSearch from '../_modules/organisms/home-search/home-search';
import Profile from '../_modules/organisms/profile/profile';
import SearchListing from '../_modules/organisms/search-listing/search-listing';
// import HomeNews from '../_modules/organisms/home-news/home-news';

$(() => {
	//Polyfill for object-fit
	objectFitImages();

	if($('.cover-page').length){
		new CoverPage();
	}

	// Apply wrapper for table
	if ($('table').length) {
		new TableResponsive();
	}

	new SiteHeader();
	new PageActions();
	new HeaderSearch();
	new BackToTop();

	if($('.text-snippet').length){
		new TextSnippet();
	}

	// new HomeNews();
	new Tab();
	new Carousel();
	new SelectClone();
	new ExpertiseContacts();
	new Form();

	if ($('.lawyer-expertise-search').length) {
		new LawyerFilters();
	}

	if ($('.resource-search').length) {
		new ResourceFilters();
	}

	if ($('.profile').length){
		new Profile();
	}

	if ($('.search-listing').length){
		new SearchListing();
	}

	$('.match-height').matchHeight();

	if ($('.tab__content').length) {
		$('.tab__category').each(function(e) {
			if ( $( this ).is( "#overview" ) ) {
				$(this).addClass('text-snippet');
			}
		});
	}
	// $('.match-height-item').matchHeight({
	// 	byRow: false,
	// 	property: 'min-height'
	// });
});
