'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class Tab {
	constructor() {
		let $tab = $('.tab-component'),
			$tabTabs = $('.tab-nav', $tab),
			$tabContent = $('.tab-target-content', $tab),
			$selected = $('.tab-nav-controller', $tab),
			$tabNav = $selected.next();

		$('a', $tabTabs).on('click', function(e){
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);
			e.stopPropagation();

			let $this = $(this);

			let target = $this.attr('href'),
				$parent = $this.parent(),
				label = $this.text();

			// Mobile dropdown handler
			$selected.html(`${label}<i class="icon-chevron-down"></i>`);
			if(!$tabNav.hasClass('active')){
				$selected.addClass('active');
				$tabNav.addClass('active');
			}
			else {
				$selected.removeClass('active');
				$tabNav.removeClass('active');
			}

			// Tab nav set/unset/active
			$('li.active', $tabTabs).removeClass('active');
			$parent.addClass('active');

			// Tab content set/unset active
			$('.active', $tabContent).fadeOut(300, function(){
				$('.active', $tabContent).removeClass('active');
				$(target).fadeIn(300, function(){
					$(target).addClass('active');
				});
			});

			// setTimeout(function() {
			// }, 275);
		});

		enquire.register("screen and (max-width: 1023px)", {
			match: function(){
				$selected.on('click', function(e){
					e.stopPropagation();

					if(!$tabNav.hasClass('active')){
						$selected.addClass('active');
						$tabNav.addClass('active');
					}
					else {
						$selected.removeClass('active');
						$tabNav.removeClass('active');
					}
				});
			},
			unmatch: function(){
				$selected.off('click');
			}
		});

		$(window).on('click scroll', function(){
			$selected.removeClass('active');
			$tabNav.removeClass('active');
		});
	}
}
