'use strict';

import $ from 'jquery';

export default class TextSnippet {
	constructor() {
		let $textSnippet = $('.text-snippet');

		$textSnippet.map((i,ele) => {
			let $thisSnippet = $(ele);

			if($('> *:not(".read-more")', $thisSnippet).length > 3){
				let $element = $('> *', $thisSnippet).not('button');

				$element.slice(3).wrapAll('<div class="read-more-content hidden"></div>');

				let $readMoreContent = $('.read-more-content', $thisSnippet);

				$readMoreContent.hide();

				$('.read-more', $thisSnippet).on('click', function(e){
					e.preventDefault ? e.preventDefault() : (e.returnValue = false);

					let $this = $(this),
						moreLabel = `${$this.data('more')}<i class="icon-left"></i>`,
						lessLabel = `${$this.data('less')}<i class="icon-left"></i>`;

					if(!$readMoreContent.hasClass('hidden')){
						$readMoreContent.slideUp(300);
						$readMoreContent.addClass('hidden');
						$this.html(moreLabel);
						$this.removeClass('less');
					}
					else {
						$readMoreContent.slideDown(300);
						$readMoreContent.removeClass('hidden');
						$this.html(lessLabel);
						$this.addClass('less');
					}
				});
			}

			else {
				$('.read-more', $thisSnippet).hide();
			}
		});

	}
}
