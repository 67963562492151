'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';
import { runInNewContext } from 'vm';

export default class Carousel {
	constructor() {
		$('.home-testimonial__content .carousel').slick({
			slidesToShow: 1,
			dots: true,
			arrows: true,
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-toggle-arrow"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icon-toggle-arrow"></i></button>'
		});

		// let $paging = $('.paging');

		// $('.expertise-contacts .carousel').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
		// 	//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		// 	let i,j;

		// 	if($(window).width() > 479){
		// 		i = (currentSlide ? currentSlide : 0) + 1;
		// 		if((i + 3 > slick.slideCount)){
		// 			j = slick.slideCount;
		// 			i = j - 3;
		// 		}
		// 		else {
		// 			j = i + 3;
		// 		}
		// 	}
		// 	else {
		// 		i = (currentSlide ? currentSlide : 0) + 1;
		// 		if((i + 1 > slick.slideCount)){
		// 			j = slick.slideCount;
		// 			i = j - 1;
		// 		}
		// 		else {
		// 			j = i + 1;
		// 		}
		// 	}

		// 	if(i === j){
		// 		$paging.html('<p>Displaying ' + i + ' of ' + slick.slideCount + '</p>');
		// 	}
		// 	else {
		// 		$paging.html('<p>Displaying ' + i + '-' + j + ' of ' + slick.slideCount + '</p>');
		// 	}
		// });

		$('.expertise-contacts .carousel').slick({
			slidesToShow: 4,
			slidesToScroll: 4,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-toggle-arrow"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icon-toggle-arrow"></i></button>',
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});

		$(window).on('resize', function(){
			setTimeout(function(){
				$('.expertise-contacts .carousel').slick('setPosition');
			}, 650);
		});

		if ($('.expertise-contacts').length){
			let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
			let beforePrint = function () {
				// console.log('Functionality to run before printing.');
				$('.expertise-contacts .carousel').slick('unslick');
				$(window).off('resize');
			};
			let afterPrint = function () {
				// console.log('Functionality to run after printing.');
				$('.expertise-contacts .carousel').slick({
				    slidesToShow: 4,
				    slidesToScroll: 4,
				    dots: false,
				    arrows: true,
				    autoplay: true,
				    autoplaySpeed: 5000,
				    prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-toggle-arrow"></i></button>',
				    nextArrow: '<button type="button" class="slick-next next"><i class="icon-toggle-arrow"></i></button>',
				    responsive: [{
				    	breakpoint: 480,
				    	settings: {
				        	slidesToShow: 2,
				        	slidesToScroll: 2
				    	}
				    }]
				});

				$(window).on('resize', function () {
					setTimeout(function () {
						$('.expertise-contacts .carousel').slick('setPosition');
					}, 650);
				});
			};

			if (window.matchMedia) {
				let mediaQueryList = window.matchMedia('print');
				mediaQueryList.addListener(function (mql) {
			    	if (mql.matches) {
						beforePrint();
			    	} else {
						afterPrint();
			    	}
				});
			}

			if(!isChrome){
				window.onbeforeprint = beforePrint;
				window.onafterprint = afterPrint;
			}
		}
	}
}
