'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class ExpertiseContacts {
	constructor() {
		let $expertiseContact = $('.expertise-contacts'),
			$viewAll = $('.view-all', $expertiseContact);

		$viewAll.on('click', function(e){
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			if (!$('.expertise-contacts .carousel').hasClass('unslicked')) {
				$('.expertise-contacts .carousel').slick('unslick');
				$('.expertise-contacts').addClass('unslicked');
				$('.match-height').matchHeight._update();
			}
		});
	}
}
