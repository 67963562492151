'use strict';
import $ from 'jquery';

export default class PageActions {
	constructor() {
		let $print = $('.page-actions .print-page a');

		$print.on('click', function(e){
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			window.print();
		});
	}
}
