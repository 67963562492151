'use strict';
import $ from 'jquery';
import queryString from 'query-string';

export default class HeaderSearch {
	constructor() {
		let $searchBar = $('.site-header__search');

		let searchURL = $searchBar.data('searchlink');

		$('button', $searchBar).on('click', function(e){
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			let searchParameters = {};
			searchParameters.searchtext = $('input', $searchBar).val();

			const query = queryString.stringify(searchParameters);

			window.location.href = `${searchURL}?${query}`;
		});

		$('input', $searchBar).keypress(function (e) {
			if (e.keyCode == 9 || e.which == 9 || e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				e.preventDefault ? e.preventDefault() : (e.returnValue = false);
				$('button', $searchBar).trigger('click');
			}
		});
	}
}
